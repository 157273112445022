export default function Logo() {
  return (
    <img
      className="h-12 lg:h-16 cursor-pointer"
      onClick={() => window.location.assign('/')}
      src="/logo.png"
      alt="logo"
    />
  );
}
