const createStyleForBackgroundImage = (
  src: string,
  opts = { withDarkOverlay: false },
) => {
  return {
    backgroundImage: `${
      opts.withDarkOverlay
        ? 'linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), '
        : ''
    }url(${src})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };
};

export default createStyleForBackgroundImage;
