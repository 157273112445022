import createStyleForBackgroundImage from '@/helper/createStyleForBackgroundImage';
import { defaultStyles } from '@/helper/defaultStyles';
import Link from 'next/link';
import { FunctionComponent } from 'react';
import Logo from './Logo';

const navbarItems = [
  {
    title: 'O nas',
    route: '/about',
  },
  {
    title: 'Kontakt',
    route: '/contact',
  },
];

const Footer: FunctionComponent = () => {
  const navItemClass =
    'uppercase cursor-pointer pt-2 border-2 border-t-0 border-l-0 border-r-0 border-solid border-transparent hover:border-default text-default transition-all duration-300 no-underline';

  return (
    <footer
      className="flex flex-col justify-center items-center"
      style={createStyleForBackgroundImage('/footer-bg.webp')}
    >
      <div className="w-full gap-8 backdrop-card py-24">
        <div
          className={`${defaultStyles.paddingBig} flex flex-col justify-center items-start gap-8`}
        >
          <Logo />
          <div className="h-[1px] w-[99%] bg-[#FFFFFF30]" />
          <div className="w-[99%] flex flex-col-reverse lg:flex-row gap-8 lg:justify-between">
            {/* <SocialIcons />ó */}
            <div className="flex flex-row items-center gap-8">
              {navbarItems.map((item, index) => (
                <Link key={index} className={navItemClass} href={item.route}>
                  {item.title}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
