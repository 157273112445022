export const theme = {
  dialog: {
    styles: {
      base: {
        backdrop: {
          backgroundColor: 'transparent',
        },
      },
    },
  },
};
