import Link from 'next/link';
import { useState } from 'react';

import { defaultStyles } from '@/helper/defaultStyles';
import BurgerIcon from './icon/BurgerIcon';
import Button from './Button';
import Logo from './Logo';

const navbarItems = [
  {
    title: 'Oferta',
    route: '/offer',
  },
  {
    title: 'O nas',
    route: '/about',
  },
];

const navItemClass =
  'uppercase cursor-pointer pt-2 border-2 border-t-0 border-l-0 border-r-0 border-solid border-transparent hover:border-default text-default transition-all duration-300 no-underline';

function Navbar() {
  const [isBurgerToggled, setIsBurgerToggled] = useState(false);

  return (
    <nav className="w-full fixed z-50 backdrop-card flex flex-col justify-center items-center py-4">
      <div className="hidden w-[90%] xl:flex flex-row items-center justify-center">
        <div
          className={`${defaultStyles.paddingBig} flex flex-row w-full justify-between items-center gap-4`}
        >
          <Logo />
          <div className="flex flex-row items-center gap-8">
            {navbarItems.map((item, index) => (
              <Link key={index} className={navItemClass} href={item.route}>
                {item.title}
              </Link>
            ))}
            <Button
              label="Kontakt"
              onClick={() => window.location.assign('/contact')}
              class="w-[145px]"
            />
          </div>
        </div>
      </div>
      <div className="w-full xl:hidden block">
        <div
          className={`${defaultStyles.paddingBig} flex flex-row justify-between items-center`}
        >
          <Logo />
          <BurgerIcon
            isToggled={isBurgerToggled}
            onClick={() => setIsBurgerToggled(!isBurgerToggled)}
          />
        </div>
      </div>
      <div className={`${isBurgerToggled ? 'block' : 'hidden'} w-full `}>
        <div
          className={`${defaultStyles.paddingBig} flex flex-col justify-start items-start py-6 gap-4`}
        >
          {navbarItems.map((item, index) => (
            <Link
              key={index}
              className={navItemClass}
              href={item.route}
              onClick={() => setIsBurgerToggled(false)}
            >
              {item.title}
            </Link>
          ))}
          <Button
            label="Kontakt"
            onClick={() => window.location.assign('/contact')}
            class="w-[145px]"
          />
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
