import { ReactNode } from 'react';

import Navbar from './Navbar';
import Footer from './Footer';

type AppWrapperType = {
  children: ReactNode;
};

const AppWrapper = ({ children }: AppWrapperType) => {
  return (
    <>
      <Navbar />
      <div className="mx-auto">{children}</div>
      <Footer />
    </>
  );
};

export default AppWrapper;
